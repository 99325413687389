import { AriaAttributes } from 'react';

import CheckedIcon from '../../assets/icons/checkbox-checked.svg';
import SquareCheckedIcon from '../../assets/icons/square-checkbox-checked.svg';
import { RMText } from '../RMText/RMText.js';

import { Checkbox, CheckboxRoot, Unchecked } from './RMCheckbox.styles.js';

interface RMCheckboxProps extends AriaAttributes {
  /**
   * The HTML id of the element
   */
  id?: string;
  /**
   * Determines if the checkbox is rendered as checked
   */
  checked: boolean;
  /**
   * Determines if the checkbox is disabled
   */
  disabled?: boolean;
  /**
   * Callback triggered when the user toggles the checkbox
   * @param checked true if the checkbox is checked
   */
  onChange?: (checked: boolean) => void;

  /**
   * Callback triggered when the user toggles the checkbox
   * @param checked true if the checkbox is checked
   */
  onBlur?: () => void;

  /**
   * An optional label for the checkbox
   */
  label?: string | null;

  /**
   * Determines if the checkbox label is bold.
   */
  bold?: boolean;

  square?: boolean;

  size?: 'md' | 'sm';
  labelSize?: 's' | 'xs';
  labelColor?: 'on-surface-primary' | 'on-surface-secondary';
  className?: string;

  /**
   * Disable all role and aria attributes. This is used when this component
   * is used to create a different widget. See RMPicker for example.
   */
  disableAccessibility?: boolean;
}

/**
 * Display a checkbox component
 */
export function RMCheckbox({
  id,
  checked,
  disabled = false,
  bold = true,
  square = false,
  onBlur,
  onChange,
  label,
  size = 'md',
  labelSize = 'xs',
  labelColor = 'on-surface-primary',
  className,
  disableAccessibility = false,
  ...props
}: RMCheckboxProps) {
  return (
    <CheckboxRoot
      id={id}
      className={className}
      data-disabled={disabled}
      onClick={() => {
        if (disabled === false) {
          onChange?.(!checked);
          onBlur?.();
        }
      }}
      {...(disableAccessibility
        ? null
        : {
            role: 'checkbox',
            'aria-checked': checked,
            'aria-label': label ?? undefined,
          })}
      {...props}
    >
      {checked ? (
        <Checkbox src={square ? SquareCheckedIcon : CheckedIcon} data-size={size} alt="checkbox" />
      ) : (
        <Unchecked data-square={square} data-disabled={disabled} data-size={size} />
      )}

      {label && (
        <RMText type="sans" size={labelSize} color={labelColor} bold={bold}>
          {label}
        </RMText>
      )}
    </CheckboxRoot>
  );
}
