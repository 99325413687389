import { styled } from '@linaria/react';

export const CheckboxRoot = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 50%;

  &[data-disabled='true'] {
    cursor: default;
    opacity: 0.4;
  }
`;

export const Checkbox = styled.img`
  height: 1.5rem;
  width: 1.5rem;
  flex: 0 0 1.5rem;

  &[data-size='sm'] {
    height: 1rem;
    width: 1rem;
    flex: 0 0 1rem;
  }
`;

export const Unchecked = styled.div`
  border: 1px solid var(--primary);
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  flex: 0 0 1.5rem;

  &[data-size='sm'] {
    height: 1rem;
    width: 1rem;
    flex: 0 0 1rem;
  }

  &[data-square='true'] {
    border-radius: var(--radius-minimal);
  }

  &[data-disabled='true'] {
    border: 1px solid var(--border-stronger);
    background-color: var(--disabled);
  }
`;
